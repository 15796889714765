.admin {
    position: relative;
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 50px;
        align-items: center;

        >p, a {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.achievement {
    &__icon {
        width: 50px;
        height: 50px;
        border-radius: 30px;
    }
}

.settings {
    margin-left: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    &__block{
        width: 300px;
        margin-left: 10px;
        margin-top: 10px;
    }
    &__text-field {
        margin-top: 10px !important;
        width: 100% !important;
    }
}