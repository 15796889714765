.progressLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 33px;
    height: 46px;
    background: url('../images/logo_loader.png') no-repeat;
    animation: 2s linear infinite rotate;
    z-index: 10;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(-360deg);
    }
  }