.table-combobox {
    min-width: 100% !important;
    max-width: 100% !important;
    height: 23px;
    font-size: 14px !important;

    &__header {
        min-width: 150px !important;
        max-width: 150px !important;
        height: 23px;
        font-size: 14px !important;
    }
}