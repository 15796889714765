.edit-form {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.3);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f5f5f5;
    z-index: 10;

    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 290px;
    }

    &__window {
        position: relative;
        background: #f5f5f5;
        width: 320px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 20px 5px 5px 5px;

        >.input {
            margin-top: 5px;
        }

        #submit-button {
            margin: 10px 0px;

        }

        #close-button {
            position: absolute;
            right: 1px;
            top: 1px;
        }
    }

    .MuiFormControlLabel-label {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}