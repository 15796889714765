.month-report-row {
    &__open-selection-achievement {
        font-size: 15px !important;
    }

    &__user-data {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__achievement-icon {
        width: 30px !important;
        height: 30px !important;
    }

    &__functional-button {
        font-size: 15px !important;
    }

    &__hight-priorty-icon{
        color: red;
    }

    &__functional-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}