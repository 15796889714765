.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.icons-block {
    padding-right: 10px;
}

.menu-item {
    margin-left: 10px !important;
    cursor: pointer;
    height: 24px;
}