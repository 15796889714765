.month-report {
    padding: 0;

    &__body {
        display: flex;

        @media screen and (max-width: 1540px) {
            flex-direction: column;
        }
    }

    &__table {
        width: 700px;
        margin: 10px;

        &__results {
            background: gainsboro;
        }
    }

    &__graphic-block {
        display: flex;

        @media screen and (max-width: 1760px) {
            flex-direction: column;
        }
    }

    &__graph-settings {
        max-width: 850px;
        padding: 10px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    &__table .MuiTableCell-root {
        padding: 0px 5px;
    }

    &__sprints-combobox {
        height: 25px;
        width: 180px !important;
    }

    &__row-title {
        display: inline-block;
        max-width: 410px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: help;
    }

    &__general-settings {
        display: flex;
        flex-direction: column;
        border-top: 2px gainsboro solid;
    }

    &__download-button {
        margin-left: 10px !important;
    }

    &__month-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }
}

.table-row {
    &__use-filter {
        cursor: pointer;
    }
}

.table-header {
    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__button {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 25px !important;
        margin-left: 5px !important;
        max-height: 25px !important;
    }
}