.authorization-form {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &__window{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #f5f5f5;
        padding: 10px;
        height: 200px;
        border-radius: 10px;
    }
}